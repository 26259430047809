var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c("order-info-tab", { attrs: { data: _vm.orderInfoData } }),
        _c("el-divider"),
        _vm.formData.status !== "Delivery Confirmed"
          ? _c(
              "el-form",
              {
                ref: "formData",
                staticClass: "demo-form",
                attrs: {
                  model: _vm.formData,
                  "status-icon": "",
                  rules: _vm.rules,
                  "label-position": "left"
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Status", required: "" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { name: "status", placeholder: "Select" },
                        model: {
                          value: _vm.formData.status,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "status", $$v)
                          },
                          expression: "formData.status"
                        }
                      },
                      _vm._l(_vm.orderOptions, function(item) {
                        return _c("el-option", {
                          key: item.key,
                          attrs: {
                            required: "",
                            label: item.label,
                            value: item.key
                          }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm.formData.status === "Order Dispatched"
                  ? [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "TransportBilty",
                            prop: "transportBilty"
                          }
                        },
                        [
                          _c("file-upload", {
                            attrs: {
                              "file-list": _vm.transportBilty,
                              "list-type": "text",
                              url: "/orders/upload"
                            },
                            on: {
                              change: function($event) {
                                _vm.formData.transportBilty = $event.response
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Invoice Attachment",
                            prop: "invoiceAttachment"
                          }
                        },
                        [
                          _c("file-upload", {
                            attrs: {
                              "file-list": _vm.invoiceAttachment,
                              "list-type": "text",
                              url: "/orders/upload"
                            },
                            on: {
                              change: function($event) {
                                _vm.formData.invoiceAttachment = $event.response
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Other Attachment",
                            prop: "otherAttachment"
                          }
                        },
                        [
                          _c("file-upload", {
                            attrs: {
                              "file-list": _vm.otherAttachment,
                              "list-type": "text",
                              url: "/orders/upload"
                            },
                            on: {
                              change: function($event) {
                                _vm.formData.otherAttachment = $event.response
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  : _vm._e(),
                _c(
                  "el-form-item",
                  { attrs: { label: "Comments", prop: "comments" } },
                  [
                    _c("el-input", {
                      attrs: { name: "comments", placeholder: "Comments" },
                      model: {
                        value: _vm.formData.comments,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "comments", $$v)
                        },
                        expression: "formData.comments"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading"
                          },
                          { name: "waves", rawName: "v-waves" }
                        ],
                        attrs: { type: "success" },
                        on: { click: _vm.submitForm }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [{ name: "waves", rawName: "v-waves" }],
                        attrs: { type: "reset" },
                        on: { click: _vm.resetForm }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                    )
                  ],
                  1
                )
              ],
              2
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }